import { OAuth2AuthorizeDTO, OAuth2AuthorizeOutputDTO, OAuth2ClientCreateDTO, OAuth2ClientInfoOutputDTO, OAuth2ClientOutputDTO, OAuth2ClientUpdateDTO, OAuth2DeviceCodeInfoOutputDTO, OAuth2DeviceVerifyDTO, OAuth2DeviceVerifyOutputDTO } from "@kalyzee/kast-app-module";
import { createAppAsyncThunk } from "../../common/helpers/utils";

import client from '../../common/helpers/api';
import { createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { IdentifiedDTO } from "../../common/helpers/types";

export interface OAuth2ExtraState {
  status: 'idle' | 'loading' | 'failed',
  info?: OAuth2ClientInfoOutputDTO, // Retrieved in authorization flow to display user
  deviceCodeInfo?: OAuth2DeviceCodeInfoOutputDTO, // Retrieved in device flow to display user
  authorization?: OAuth2AuthorizeOutputDTO,
  verification?: OAuth2DeviceVerifyOutputDTO,
  error?: any,
  notification?: string,
}

export type OAuth2State = EntityState<OAuth2ClientOutputDTO> & OAuth2ExtraState;

export const authorize = createAppAsyncThunk(
  'oauth2/authorize',
  async (data: OAuth2AuthorizeDTO) => {
      const response = await client.oAuth2.authorize(data);
      return response.data;
  }
)

export const fetchOne = createAppAsyncThunk(
  'oauth2/fetchOne',
  async (id: string) => {
      const response = await client.oAuth2.getById(id);
      return response.data;
  }
)

export const fetchInfo = createAppAsyncThunk(
  'oauth2/fetchInfo',
  async (id: string) => {
      const response = await client.oAuth2.getInfoById(id);
      return response.data;
  }
)

export const fetchAll = createAppAsyncThunk(
  'oauth2/fetchAll',
  async () => {
      const response = await client.oAuth2.getAll();
      return response.data;
  }
)

export const create = createAppAsyncThunk(
  'oauth2/create',
  async (data: OAuth2ClientCreateDTO) => {
    const response = await client.oAuth2.create(data);
    return response.data;
  }
)

export const update = createAppAsyncThunk(
  'oauth2/update',
  async (data: IdentifiedDTO<OAuth2ClientUpdateDTO>) => {
    const response = await client.oAuth2.update(data.id, data.dto);
    return response.data;
  }
)

export const regenerateSecret = createAppAsyncThunk(
  'oauth2/regenerateSecret',
  async (id: string) => {
    const response = await client.oAuth2.regenerateSecret(id);
    return response.data;
  }
)

export const remove = createAppAsyncThunk(
  'oauth2/remove',
  async (id: string) => {
    const response = await client.oAuth2.delete(id);
    return response.data;
  }
)

export const deviceVerify = createAppAsyncThunk(
  'oauth2/deviceVerify',
  async (data: OAuth2DeviceVerifyDTO) => {
    const response = await client.oAuth2.deviceVerify(data);
    return response.data;
  }
)

export const fetchDeviceCodeInfo = createAppAsyncThunk(
  'oauth2/fetchDeviceCodeInfo',
  async (userCode: string) => {
    const response = await client.oAuth2.getDeviceCodeInfoByUserCode(userCode);
    return response.data;
  }
)

export const oAuth2ClientsAdapter = createEntityAdapter<OAuth2ClientOutputDTO>({
  selectId: (client) => client._id,
});

const initialState: OAuth2State = oAuth2ClientsAdapter.getInitialState({ 
  status: 'idle',
  authorization: undefined,
  error: undefined,
});

export const oAuth2Slice = createSlice({
  name: 'oauth2',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    notify: (state, { payload }) => {
      state.notification = payload;
    },
    resetNotification: (state) => {
      state.notification = undefined;
    },
    resetError: (state) => {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authorize.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(authorize.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.authorization = payload;
        state.error = undefined;
      })
      .addCase(authorize.rejected, (state, { payload }) => {
        state.status = 'failed';
        state.authorization = undefined;
        state.error = payload;
      })
      .addCase(fetchOne.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOne.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = undefined;
        oAuth2ClientsAdapter.setOne(state, action);
      })
      .addCase(fetchOne.rejected, (state, { payload }) => {
        state.status = 'failed';
        state.error = payload;
      })
      .addCase(fetchInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchInfo.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.error = undefined;
        state.info = payload;
      })
      .addCase(fetchInfo.rejected, (state, { payload }) => {
        state.status = 'failed';
        state.error = payload;
      })
      .addCase(create.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(create.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = undefined;
        oAuth2ClientsAdapter.setOne(state, action);
      })
      .addCase(create.rejected, (state, { payload }) => {
        state.status = 'failed';
        state.error = payload;
      })
      .addCase(update.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(update.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = undefined;
        oAuth2ClientsAdapter.setOne(state, action);
      })
      .addCase(update.rejected, (state, { payload }) => {
        state.status = 'failed';
        state.error = payload;
      })
      .addCase(regenerateSecret.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(regenerateSecret.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = undefined;
        oAuth2ClientsAdapter.setOne(state, action);
      })
      .addCase(regenerateSecret.rejected, (state, { payload }) => {
        state.status = 'failed';
        state.error = payload;
      })
      .addCase(fetchAll.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAll.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = undefined;
        oAuth2ClientsAdapter.setAll(state, action);
      })
      .addCase(fetchAll.rejected, (state, { payload }) => {
        state.status = 'failed';
        state.error = payload;
      })
      .addCase(remove.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(remove.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.error = undefined;
        oAuth2ClientsAdapter.removeOne(state, payload.id);
      })
      .addCase(remove.rejected, (state, { payload }) => {
        state.status = 'failed';
        state.error = payload;
      })
      .addCase(fetchDeviceCodeInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDeviceCodeInfo.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.error = undefined;
        state.deviceCodeInfo = payload;
      })
      .addCase(fetchDeviceCodeInfo.rejected, (state, { payload }) => {
        state.status = 'failed';
        state.error = payload;
      })
      .addCase(deviceVerify.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deviceVerify.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.verification = payload;
        state.error = undefined;
      })
      .addCase(deviceVerify.rejected, (state, { payload }) => {
        state.status = 'failed';
        state.verification = undefined;
        state.error = payload;
      })
  },
});

export const { resetError, notify, resetNotification } = oAuth2Slice.actions;

export const selectAuthorization = (state: RootState) => state.oauth2.authorization;
export const selectError = (state: RootState) => state.oauth2.error;
export const selectNotification = (state: RootState) => state.oauth2.notification;
export const selectInfo = (state: RootState) => state.oauth2.info;
export const selectDeviceCodeInfo = (state: RootState) => state.oauth2.deviceCodeInfo;
export const selectVerification = (state: RootState) => state.oauth2.verification;
export const selectStatus = (state: RootState) => state.oauth2.status;

export const {
  selectById: selectOAuth2ClientById,
  selectAll: selectAllOAuth2Clients,
} = oAuth2ClientsAdapter.getSelectors((state: RootState) => state.oauth2);

export default oAuth2Slice.reducer;
