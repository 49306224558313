import { useParams } from "react-router-dom";
import { ClientUpdateForm } from "../../features/oauth2/components/ClientUpdateForm";

import containersStyle from '../../common/css/containers.module.css';

type ClientUpdatePageParams = {
    id: string,
}

export const ClientUpdatePage = () => {
    const { id } = useParams<ClientUpdatePageParams>();

    if (!id) return null; // Impossible

    return (
        <div className={containersStyle.formContainerLarge}>
            <ClientUpdateForm id={id} />
        </div>
    );
}