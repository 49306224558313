import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { logout } from '../../auth/extraActions';
import styles from './NavBar.module.css';

export interface NavBarProps {
    displayMenu?: boolean,
}

export const NavBar = ({ displayMenu }: NavBarProps) => {
    const dispatch = useAppDispatch();
    const [logoutTimer, setLogoutTimer] = useState(0);

    useEffect(() => {
        if (logoutTimer === 0) return;
        const id = setTimeout(() => {
            setLogoutTimer((v) => v - 1);
        }, 1000);
        return () => clearTimeout(id);
    }, [logoutTimer]);

    const onClickLogout = () => {
        if (logoutTimer === 0) {
            setLogoutTimer(5);
        } else {
            dispatch(logout());
        }
    }

    const containerJustifyContent = displayMenu ? 'space-between' : 'right';

    return (
        <div className={styles.navContainer} style={{ justifyContent: containerJustifyContent }}>
            {displayMenu && <div style={{
                display: 'flex',
            }}>
                <Link to='/client' className={styles.navButton}>
                    Clients List
                </Link>
                <Link to='/client/create' className={styles.navButton}>
                    Create
                </Link>
            </div>}
            <div style={{
                display: 'flex',
            }}>
                <button onClick={onClickLogout} className={`${styles.navButton} ${styles.navButtonLogout}`}>
                    Logout {logoutTimer > 0 && `(Click to confirm ${logoutTimer})`}
                </button>
            </div>
        </div>
    );
}