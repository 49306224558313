import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { clearTokens } from "../../common/helpers/tokens";

/**
 * logout and reset actions are being isolated in separated files to avoid the following circular dependency :
 * - all slices are depending on the common/utils file because they use the createAsyncAppThunk
 * - the createAsyncAppThunk depends on the authSlice to be abble to dispatch a logout() in case of 403 error code
 * - the logout thunk then depends on all the slices to get their reset actions, there is a circular dependency
 * => the logout and all the reset actions were isolated from the slices
 */

const resetAuth = createAction('auth/reset');

const logout = createAsyncThunk(
    'auth/logout',
    async (foo, { dispatch }) => {
        clearTokens();
        dispatch(resetAuth());
    }
);
 
export {
    resetAuth,
    logout,
}