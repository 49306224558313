import { UserLoginLocalDTO } from "@kalyzee/kast-app-module";
import { createAppAsyncThunk } from "../../common/helpers/utils";

import client from '../../common/helpers/api';
import { getToken, setToken, TOKEN_TYPE } from "../../common/helpers/tokens";
import { createSlice } from "@reduxjs/toolkit";
import { resetAuth } from "./extraActions";
import { RootState } from "../../app/store";

export interface AuthState {
  status: 'idle' | 'loading' | 'failed',
  token?: string,
  refreshToken?: string,
  error?: any,
}

export const login = createAppAsyncThunk(
  'auth/login',
  async (data: UserLoginLocalDTO) => {
      return await client.login(data.email, data.password);
  }
)

const makeInitialState = (): AuthState => ({ 
  status: 'idle',
  token: getToken(TOKEN_TYPE.NORMAL),
  refreshToken: getToken(TOKEN_TYPE.REFRESH),
  error: undefined,
});

const initialState: AuthState = makeInitialState();

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetError: (state) => {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.error = undefined;
        if (payload?.token) {
          state.token = payload.token;
          setToken(TOKEN_TYPE.NORMAL, payload.token);
        }
        if (payload?.refreshToken) {
          state.refreshToken = payload.refreshToken;
          setToken(TOKEN_TYPE.REFRESH, payload.refreshToken);
        }
      })
      .addCase(login.rejected, (state, { payload }) => {
        state.status = 'failed';
        state.error = payload;
      })
      .addCase(resetAuth, () => {
        return makeInitialState();
      })
  },
});

export const { resetError } = authSlice.actions;

export const selectError = (state: RootState) => state.auth.error;
export const selectToken = (state: RootState) => state.auth.token;
export const selectRefreshToken = (state: RootState) => state.auth.refreshToken;
export const selectStatus = (state: RootState) => state.auth.status;

export default authSlice.reducer;