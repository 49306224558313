import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import componentsStyles from '../../../common/css/components.module.css';
import { fetchAll, notify, remove, selectAllOAuth2Clients } from '../oauth2Slice';
import { useEffect, useState } from 'react';
import { OAuth2ClientOutputDTO, OAUTH2_CLIENT_STATUS } from '@kalyzee/kast-app-module';
import { useNavigate } from 'react-router-dom';
import { DeleteComponent } from '../../../common/components/PopUpComponent';


export const ClientList = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const clients = useAppSelector((state) => selectAllOAuth2Clients(state));

    const [toDelete, setToDelete] = useState<OAuth2ClientOutputDTO|undefined>(undefined);

    useEffect(() => {
        dispatch(fetchAll({ data: {} }));
        
    }, [dispatch]);

    const renderStatus = (status: OAUTH2_CLIENT_STATUS) => {
        return (
            <div style={{
                padding: 5,
                borderRadius: 5,
                backgroundColor: status === OAUTH2_CLIENT_STATUS.ENABLED ? '#beeba2' : '#eb8181',
            }}>{status}</div>
        );
    }

    const renderButtons = (client: OAuth2ClientOutputDTO) => {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 5,
            }}>
                <button onClick={() => navigate(`edit/${client.id}`)} className={componentsStyles.button}>Edit</button>
                <button onClick={() => setToDelete(client)} className={componentsStyles.button}>Delete</button>
            </div>
        )
    }

    const renderClientLign = (client: OAuth2ClientOutputDTO) => {
        return (
            <div style={{ 
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 10,
                // backgroundColor: 'rgb(180, 180, 180)', 
                padding: 10,
                // color: 'white'
                borderBottom: '1px solid black',
            }} key={`client-${client.id}`}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 10,
                    alignItems: 'center'
                }}>
                    {renderStatus(client.status)}
                    <div>{client.name}</div>
                </div>
                {renderButtons(client)}
            </div>       
        );
    }

    return (
        <div className={componentsStyles.form} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <h1 className={componentsStyles.titleText}>Your Clients</h1>
            <div style={{ 
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '50vh', 
                overflow: 'scroll',
                width: '100%',
                gap: 10,
            }}>
                {clients.map((client) => renderClientLign(client))}
            </div>
            { toDelete ? (
                <DeleteComponent 
                    ressourceName={toDelete.name}
                    onConfirm={() => {
                        dispatch(remove({ 
                            data: toDelete.id, 
                            onSuccess: () => dispatch(notify(`${toDelete.name} deleted!`)),
                        }));
                        setToDelete(undefined);
                    }}
                    onCancel={() => setToDelete(undefined)}
                />
            )  : null }    
        </div>
    );
}