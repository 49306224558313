import componentsStyles from '../../../common/css/components.module.css';
import styles from './AuthorizeForm.module.css';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { authorize, fetchInfo, selectAuthorization, selectError, selectInfo } from '../oauth2Slice';
import { ReactNode, useEffect } from 'react';
import { OAuth2AuthorizeDTO, OAUTH2_RESPONSE_TYPE } from '@kalyzee/kast-app-module';

import Logo from '../../../assets/png/icon-logo.png';
import { ReactComponent as Swap } from '../../../assets/svg/swap-horizontal-outline.svg'
import { ReactComponent as Layers } from '../../../assets/svg/layers-outline.svg';
import { ReactComponent as CloseCircle } from '../../../assets/svg/close-circle-outline.svg'
import { extractErrorMessage } from '../../../common/helpers/utils';

export interface AuthorizeFormProps {
    clientId: string | null,
    redirectUri: string | null,
    responseType: string | null,
    state: string | null,
    codeChallenge: string | null,
    codeChallengeMethod: string | null,
}

export const AuthorizeForm = ({ 
    clientId,
    redirectUri,
    responseType,
    state,
    codeChallenge,
    codeChallengeMethod,
}: AuthorizeFormProps) => {
    const dispatch = useAppDispatch();

    const error = useAppSelector((state) => selectError(state));
    const authorization = useAppSelector((state) => selectAuthorization(state));
    const clientInfo = useAppSelector((state) => selectInfo(state));

    useEffect(() => {
        if (clientId) {
            if (!clientInfo || clientInfo.id !== clientId) {
                dispatch(fetchInfo({ data: clientId }));
            }
        }
    }, [clientId, clientInfo, dispatch]);

    useEffect(() => {
        if (authorization) {
            window.location.replace(authorization.redirectUri);
        }
    }, [authorization]);

    const renderError = (errorContent: ReactNode) => {
        return (
            <div className={componentsStyles.form}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className={styles.header}>
                        <CloseCircle className={styles.companyLogo} style={{ color: 'red' }} />
                        <h1 className={styles.title}><b>Invalid authorization request</b></h1>
                    </div>
                </div>
                <div>
                    <p className={styles.title}>{errorContent}</p>
                </div>
            </div>
        );
    }

    if (!clientId || !redirectUri || !responseType) return renderError(
        <>Missing parameters.</>
    );  

    if (error) {
        const message = extractErrorMessage(error);
        return renderError(<>{message}</>);
    }

    if (!clientInfo) return <p>Loading...</p>;

    const onUserChoose = (allowed: boolean) => {
        let decodedUri = '';
        try {
            decodedUri = decodeURI(redirectUri);
        } catch {}
        const authorizeData: OAuth2AuthorizeDTO = {
            client_id: clientId,
            redirect_uri: decodedUri,
            response_type: responseType as OAUTH2_RESPONSE_TYPE,
            allowed,
        };
        if (state) authorizeData.state = state;
        if (codeChallenge) authorizeData.code_challenge = codeChallenge;
        if (codeChallengeMethod) authorizeData.code_challenge_method = codeChallengeMethod;
        dispatch(authorize({ data: authorizeData }));
    }

    return (
        <div className={componentsStyles.form}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className={styles.header}>
                    {clientInfo.imageUrl ? 
                        <img alt='kalyzee_logo' className={styles.companyLogo} src={clientInfo.imageUrl} /> 
                        : <img alt='kalyzee_logo' className={styles.companyLogo} src={Logo} />}
                    <Swap className={styles.transferLogo} />
                    <Layers className={styles.companyLogo} />
                </div>
            </div>
            <div>
                <h1 className={styles.title}><b>{clientInfo.name}</b> wishes to access your <b>Kalyzée</b> account</h1>
                <div className={styles.buttonsContainer}> 
                    <div className={styles.buttonWrapper}>
                        <button onClick={() => onUserChoose(true)} className={componentsStyles.button}>Accept</button>
                    </div>
                    <div className={styles.buttonWrapper}>
                        <button onClick={() => onUserChoose(false)} className={componentsStyles.button}>Deny</button>
                    </div>
                </div>
            </div>
        </div>
    );
}