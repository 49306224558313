import { OAuth2ClientCreateDTO, OAuth2ClientOutputDTO, OAuth2ClientUpdateDTO, OAUTH2_CLIENT_STATUS, OAUTH2_FLOW_TYPE } from "@kalyzee/kast-app-module";

export interface ClientCreateFormFields {
    name: string,
    flowType: OAUTH2_FLOW_TYPE,
    redirectUris: { uri: string }[], // react-hook-forms useFieldArray only accept arrays of objects
    flowSecurity: string[],
}

export interface ClientUpdateFormFields {
    name: string,
    flowType: OAUTH2_FLOW_TYPE,
    redirectUris: { uri: string }[], // react-hook-forms useFieldArray only accept arrays of objects
    flowSecurity: string[],
    enabled: boolean,
}

export const clientCreateOutputFormater = ({
    name,
    flowType,
    redirectUris,
    flowSecurity,
}: ClientCreateFormFields): OAuth2ClientCreateDTO => {
    return {
        name,
        flowType,
        redirectUris: flowType === OAUTH2_FLOW_TYPE.AUTHORIZATION_CODE ? redirectUris.map((uriObj) => uriObj.uri) : undefined,
        useClientSecret: flowSecurity.includes('useClientSecret'),
        usePkce: flowSecurity.includes('usePkce'),
    };
}

export const clientUpdateInputFormater = ({
    name,
    flowType,
    redirectUris,
    useClientSecret,
    usePkce,
    status,
}: OAuth2ClientOutputDTO): ClientUpdateFormFields => {
    const flowSecurity = [];
    if (useClientSecret) flowSecurity.push('useClientSecret');
    if (usePkce) flowSecurity.push('usePkce');
    return {
        name,
        flowType,
        redirectUris: redirectUris ? redirectUris.map((uri) => ({ uri })) : [],
        flowSecurity,
        enabled: status === OAUTH2_CLIENT_STATUS.ENABLED,
    };
}

export const clientUpdateOutputFormater = ({
    name,
    flowType,
    redirectUris,
    flowSecurity,
    enabled,
}: ClientUpdateFormFields): OAuth2ClientUpdateDTO => {
    return {
        name,
        redirectUris: flowType === OAUTH2_FLOW_TYPE.AUTHORIZATION_CODE ? redirectUris.map((uriObj) => uriObj.uri) : undefined,
        useClientSecret: flowSecurity.includes('useClientSecret'),
        usePkce: flowSecurity.includes('usePkce'),
        status: enabled ? OAUTH2_CLIENT_STATUS.ENABLED : OAUTH2_CLIENT_STATUS.DISABLED,
    };
}
