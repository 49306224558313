import componentsStyles from '../css/components.module.css';
import styles from './PopUpComponent.module.css';

export interface DeleteComponentProps {
    ressourceName: string,
    onConfirm?: () => void,
    onCancel?: () => void,
}

export const DeleteComponent = ({
    ressourceName,
    onConfirm,
    onCancel,
}: DeleteComponentProps) => {

    return (
        <div className={styles.container}>
            <div style={{
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 10,
                width: '100%',
                gap: 20,
                backgroundColor: 'white',
                padding: 15,
                boxShadow: '0px 1px 2px 0px rgba(60,64,67,.30),0px 2px 6px 2px rgba(60,64,67,.15)',
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    width: '100%',
                    borderBottom: '1px solid black',
                    paddingBottom: 5,
                }}>
                    Delete
                </div>
                <div>
                    Are you sure you want to delete <b>{ressourceName}</b> ?
                </div>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    gap: 10,
                }}>
                    <button onClick={() => onConfirm?.()} className={componentsStyles.button} style={{ flex: 1 }}>Confirm</button>
                    <button onClick={() => onCancel?.()} className={componentsStyles.button} style={{ flex: 1 }}>Cancel</button>
                </div>
            </div>
        </div>
    );

}