import { PropsWithChildren } from 'react';
import styles from './Notifier.module.css';

export enum NotifierType {
    Success = 'Success',
    Error = 'Error',
}

export interface NotifierProps {
    type: NotifierType,
    onClose?: () => void,
}

export const Notifier = ({ 
    type, 
    onClose,
    children,
}: PropsWithChildren<NotifierProps>) => {

    return (
        <div className={`${styles.notifierContainer} ${type === NotifierType.Error ? styles.notifierContainerError : ''}`}>
            <div className={styles.notifierHeader}>
                <div className={styles.notifierHeaderTitle}>{type}</div>
                <button onClick={() => onClose?.()} className={styles.notifierHeaderCloseBtn}>Close</button>
            </div>
            <div className={styles.notifierBody}>
                {children}
            </div>
        </div>  
    );

}