import { useEffect } from "react"
import { Outlet } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { Notifier, NotifierType } from "../../common/components/Notifier"
import { NavBar } from "../../features/oauth2/components/NavBar"
import { resetNotification, selectNotification } from "../../features/oauth2/oauth2Slice"

import styles from './ClientPage.module.css';

export const ClientPage = () => {
    const dispatch = useAppDispatch();
    const notification = useAppSelector((state) => selectNotification(state));

    const closeNotification = () => {
        dispatch(resetNotification());
    }

    useEffect(() => {
        const id = setTimeout(() => {
            closeNotification();
        }, 5000);
        return () => clearTimeout(id);
    }, [notification]);

    const renderNotification = () => {
        if (!notification) return null;
        return (
            <div className={styles.notifierContainer}>
                <Notifier 
                    type={NotifierType.Success}
                    onClose={closeNotification}
                >{notification}</Notifier>
            </div>
        );
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 10,
        }}>
            <div style={{
                display: 'flex',
                width: '100%',
            }}>
                <NavBar displayMenu />
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                flex: 1,
            }}>
                <Outlet />
            </div>
            {renderNotification()}
        </div>
    )
}