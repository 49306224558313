import { extractErrorMessage } from "../helpers/utils";
import { Notifier, NotifierType } from "./Notifier";

import styles from './ErrorNotifier.module.css';

export interface ErrorNotifierProps {
    error: any,
    onClose: () => void,
}

export const ErrorNotifier = ({
    error,
    onClose,
}: ErrorNotifierProps) => {
    if (!error) return null;

    const message = extractErrorMessage(error);

    return (
        <div className={styles.notifierContainer}>
            <Notifier 
                type={NotifierType.Error}
                onClose={onClose}
            >{message}</Notifier>
        </div>
    );
}