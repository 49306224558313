import { useAppSelector } from "../../app/hooks";
import { selectToken } from "../../features/auth/authSlice";
import { LoginForm } from "../../features/auth/components/LoginForm";

import containersStyles from '../../common/css/containers.module.css';
import styles from './GlobalWrapper.module.css';
import { Outlet } from "react-router-dom";

export const GlobalWrapper = () => {
    const token = useAppSelector(selectToken);

    return (
        <div style={{ width: '100vw', height: '100vh' }}>
            <div className={styles.blurredBackground} />
            <div className={styles.backgroundOverride}>
                <div className={containersStyles.mainContainer}>
                    { 
                        token ? <Outlet /> : 
                        <div className={containersStyles.formContainer}>
                            <LoginForm />
                        </div> 
                    }
                </div>
            </div>
        </div>
    );
}