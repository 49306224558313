import componentsStyles from '../../../common/css/components.module.css';
import styles from './AuthorizeForm.module.css';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { deviceVerify, fetchDeviceCodeInfo, selectDeviceCodeInfo, selectError, selectVerification } from '../oauth2Slice';
import { ReactNode, useEffect } from 'react';
import { OAuth2DeviceVerifyDTO, OAUTH2_DEVICE_CODE_STATUS } from '@kalyzee/kast-app-module';

import Logo from '../../../assets/png/icon-logo.png';
import { ReactComponent as Swap } from '../../../assets/svg/swap-horizontal-outline.svg'
import { ReactComponent as Layers } from '../../../assets/svg/layers-outline.svg';
import { ReactComponent as CloseCircle } from '../../../assets/svg/close-circle-outline.svg'
import { extractErrorMessage } from '../../../common/helpers/utils';

export interface VerifyDeviceFormProps {
    userCode: string,
}

export const VerifyDeviceForm = ({ 
    userCode,
}: VerifyDeviceFormProps) => {
    const dispatch = useAppDispatch();

    const error = useAppSelector((state) => selectError(state));
    const deviceCodeInfo = useAppSelector((state) => selectDeviceCodeInfo(state));
    const verification = useAppSelector((state) => selectVerification(state));

    useEffect(() => {
        if (userCode) {
            if (!deviceCodeInfo || deviceCodeInfo.userCode !== userCode) {
                dispatch(fetchDeviceCodeInfo({ data: userCode }));
            }
        }
    }, [userCode, deviceCodeInfo, dispatch]);

    const renderError = (errorContent: ReactNode) => {
        return (
            <div className={componentsStyles.form}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className={styles.header}>
                        <CloseCircle className={styles.companyLogo} style={{ color: 'red' }} />
                        <h1 className={styles.title}><b>Invalid verification request</b></h1>
                    </div>
                </div>
                <div>
                    <p className={styles.title}>{errorContent}</p>
                </div>
            </div>
        );
    } 

    if (error) {
        const message = extractErrorMessage(error);
        return renderError(<>{message}</>);
    }

    if (!deviceCodeInfo) return <p>Loading...</p>;

    // Could also check expiration ahead here :
    if (deviceCodeInfo.status !== OAUTH2_DEVICE_CODE_STATUS.PENDING) 
        return renderError(<>Already verified</>);

    const onUserChoose = (allow: boolean) => {
        const verifyData: OAuth2DeviceVerifyDTO = {
            user_code: userCode,
            allow,
        };
        dispatch(deviceVerify({ data: verifyData }));
    }

    const renderContent = () => {
        if (verification) {
            return (
                <h1 className={styles.title}>You {verification.status} the device access!</h1>
            )
        } else {
            return (
                <>
                    <h1 className={styles.title}><b>{deviceCodeInfo?.client.name}</b> wishes to access your <b>Kalyzée</b> account</h1>
                    <div className={styles.buttonsContainer}> 
                        <div className={styles.buttonWrapper}>
                            <button onClick={() => onUserChoose(true)} className={componentsStyles.button}>Accept</button>
                        </div>
                        <div className={styles.buttonWrapper}>
                            <button onClick={() => onUserChoose(false)} className={componentsStyles.button}>Deny</button>
                        </div>
                    </div>
                </>
            );
        }
    }

    return (
        <div className={componentsStyles.form}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className={styles.header}>
                    {deviceCodeInfo?.client.imageUrl ? 
                        <img alt='kalyzee_logo' className={styles.companyLogo} src={deviceCodeInfo?.client.imageUrl} /> 
                        : <img alt='kalyzee_logo' className={styles.companyLogo} src={Logo} />}
                    <Swap className={styles.transferLogo} />
                    <Layers className={styles.companyLogo} />
                </div>
            </div>
            <div>
                {renderContent()}
            </div>
        </div>
    );
}