import { useSearchParams } from 'react-router-dom';
import { AuthorizeForm } from '../../features/oauth2/components/AuthorizeForm';

import containersStyle from '../../common/css/containers.module.css';
import { NavBar } from '../../features/oauth2/components/NavBar';

export const AuthorizePage = () => {
    const [params] = useSearchParams();

    const clientId = params.get('client_id');
    const redirectUri = params.get('redirect_uri');
    const responseType = params.get('response_type');
    const state = params.get('state');
    const codeChallenge = params.get('code_challenge');
    const codeChallengeMethod = params.get('code_challenge_method');

    const renderAuthorizationForm = () => (
        <div className={containersStyle.formContainer}>
            <AuthorizeForm 
                clientId={clientId}
                redirectUri={redirectUri}
                responseType={responseType}
                state={state}
                codeChallenge={codeChallenge}
                codeChallengeMethod={codeChallengeMethod}
            />
        </div>
    );

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 10,
        }}>
            <div style={{
                display: 'flex',
                width: '100%',
            }}>
                <NavBar />
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                flex: 1,
            }}>
                {renderAuthorizationForm()}
            </div>
        </div>
    )
}