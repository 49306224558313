import './App.css';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import { AuthorizePage } from './pages/authorize/AuthorizePage';
import { GlobalWrapper } from './common/pages/GlobalWrapper';
import { ClientCreatePage } from './pages/client/ClientCreatePage';
import { ClientUpdatePage } from './pages/client/ClientUpdatePage';
import { ClientListPage } from './pages/client/ClientListPage';
import { ClientPage } from './pages/client/ClientPage';
import { DevicePage } from './pages/device/DevicePage';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<GlobalWrapper />} errorElement={<Navigate to='client' />}>
      <Route path='' element={<Navigate to='client' />} />
      <Route path='authorize' element={<AuthorizePage />} />
      <Route path='device' element={<DevicePage />} />
      <Route path='client' element={<ClientPage />}>
        <Route path='' element={<ClientListPage />} />
        <Route path='create' element={<ClientCreatePage />} />
        <Route path='edit/:id' element={<ClientUpdatePage />} />
      </Route>
    </Route>
  )
)

function App() {
  return <RouterProvider router={router} />
}

export default App;
