import { useEffect, useState } from 'react';
import containersStyle from '../../common/css/containers.module.css';
import { VerifyDeviceForm } from '../../features/oauth2/components/VerifyDeviceForm';
import { NavBar } from '../../features/oauth2/components/NavBar';
import { UserCodeForm } from '../../features/oauth2/components/UserCodeForm';

export const DevicePage = () => {
    const [mounted, setMounted] = useState(false);
    const [userCode, setUserCode] = useState<string|undefined>(undefined);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        if (code) setUserCode(code);
        setMounted(true); // So we don't display form before knowing if we have a code in url
    }, []);

    const renderAuthorizationForm = (code: string) => {
        return (
            <div className={containersStyle.formContainer}>
                <VerifyDeviceForm 
                    userCode={code}
                />
            </div>
        );
    }

    const renderUserCodeForm = () => (
        <div className={containersStyle.formContainer}>
             <UserCodeForm onCodeConfirmed={(code) => setUserCode(code)} />
         </div>
    );

    if (!mounted) return null;

    const renderContent = () => {
        return userCode ? renderAuthorizationForm(userCode) : renderUserCodeForm();
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 10,
        }}>
            <div style={{
                display: 'flex',
                width: '100%',
            }}>
                <NavBar />
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                flex: 1,
            }}>
                {renderContent()}
            </div>
        </div>
    )
}