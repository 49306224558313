import KastAppClient, { LOGIN_LEVEL } from '@kalyzee/kast-app-module';
import { getToken, TOKEN_TYPE } from './tokens';

const getBackendUrl = (): string => {
    const url = new URL(window.location.href);
    const hostNameSplitted = url.hostname.split('.');
    hostNameSplitted.splice(0, 1);
    const hostNameStr = hostNameSplitted.join('.');
    // return 'https://abo.dev.kalyzee.com/606ee9c4bf11b4001c9ac54b';
    return `${url.protocol}//api.${hostNameStr}`;
};
  
const token = getToken(TOKEN_TYPE.NORMAL);
const refreshToken = getToken(TOKEN_TYPE.REFRESH);
const clientTokens = {
    token: token ? token : undefined,
    refreshToken: refreshToken ? refreshToken : undefined,
}

const client = new KastAppClient(
    getBackendUrl(), LOGIN_LEVEL.NORMAL, clientTokens,
);

export {
    client,
}

export default client;