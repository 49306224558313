import * as yup from 'yup';

import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { ClientUpdateFormFields, clientUpdateInputFormater, clientUpdateOutputFormater } from "../models";
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import componentsStyles from '../../../common/css/components.module.css';
import { fetchOne, notify, regenerateSecret, resetError, selectError, selectOAuth2ClientById, selectStatus, update } from '../oauth2Slice';
import { useEffect } from 'react';
import { ErrorNotifier } from '../../../common/components/ErrorNotifier';
import { OAUTH2_FLOW_TYPE } from '@kalyzee/kast-app-module';

interface ClientUpdateFormProps {
    id: string,
}

const schema = yup.object({
    name: yup.string().required(),
    flowSecurity: yup.array(),
    redirectUris: yup.array().when('flowType', {
        is: OAUTH2_FLOW_TYPE.AUTHORIZATION_CODE,
        then: yup.array().min(1, 'You must have atleast 1 URI').max(10, 'You must have maximum 10 URIs').of(
            yup.object({
                uri: yup.string().url('Must be a valid URL').required('This field is required'),
            }),
        ),
    }),
}).required();

export const ClientUpdateForm = ({ id }: ClientUpdateFormProps) => {
    const dispatch = useAppDispatch();

    const error = useAppSelector((state) => selectError(state));
    const client = useAppSelector((state) => selectOAuth2ClientById(state, id));
    const status = useAppSelector((state) => selectStatus(state));

    const { 
        control,
        watch,
        register, 
        handleSubmit,
        setError,
        clearErrors,
        formState: { errors },
        reset,
    } = useForm<ClientUpdateFormFields>({
        resolver: yupResolver(schema),
        defaultValues: {
            redirectUris: [{ uri: '' }],
            flowSecurity: [],
        },
    });

    const flowTypeWatcher = watch('flowType');

    useEffect(() => {
        if (status !== 'loading' && !client) dispatch(fetchOne({ data: id }));
    }, []);

    useEffect(() => {
        if (status === 'idle' && client) {
            reset(clientUpdateInputFormater(client));
        }
    }, [status, client, reset]);

    const { fields, insert, remove } = useFieldArray({
        control,
        name: 'redirectUris',
    });

    const setUrisError = (isMin=true) => {
        setError('redirectUris', { 
            type: 'custom', 
            message: isMin ? 'You must have atleast 1 URI' : 'You must have maximum 10 URIs',
        });
    }

    const clearUrisError = () => {
        if (errors.redirectUris) clearErrors('redirectUris');
    }

    const addUri = () => {
        if (fields.length >= 10) {
            setUrisError(false);
        } else {
            clearUrisError();
        }
        insert(fields.length, { uri: '' });
    };

    const removeUri = (index: number) => {
        if (fields.length === 1) {
            setUrisError();
        } else if (fields.length <= 11) {
            clearUrisError();
        }
        remove(index);
    }

    const onSubmit: SubmitHandler<ClientUpdateFormFields> = (data: ClientUpdateFormFields) => {
        dispatch(update({
            data: {
                id,
                dto: clientUpdateOutputFormater(data)
            },
            onSuccess: (resData) => dispatch(notify(`${resData.name} updated!`)),
        }));
    }

    const onResetSecret = (id: string) => {
        dispatch(regenerateSecret({ data: id }));
    }

    const closeError = () => {
        dispatch(resetError());
    }

    const getInputClass = (hasError: boolean) => {
        return `${componentsStyles.input} ${hasError ? componentsStyles.inputError : ''}`;
    }

    return (
        <form className={componentsStyles.form}
            onSubmit={handleSubmit(onSubmit)}
        >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <h1 className={componentsStyles.titleText}>Edit a Client</h1>
            </div>
            <div className={componentsStyles.formBody}>
                <ErrorNotifier error={error} onClose={closeError} />
                <div className={componentsStyles.formBodyColumn}>
                    <div className={componentsStyles.inputGroup}>
                        <label className={componentsStyles.labelText} htmlFor='name'>Name</label>
                        <input 
                            {...register('name')} 
                            type='text' 
                            id='name' 
                            name='name' 
                            className={getInputClass(!!errors.name)} 
                        />
                        {errors.name && <div className={componentsStyles.errorText}>{errors.name.message}</div>}
                    </div>
                    <div className={componentsStyles.inputGroup}>
                        <label className={componentsStyles.labelText} htmlFor='name'>Flow type</label>
                        <select 
                            {...register('flowType')}
                            id='flowType' 
                            name='flowType' 
                            className={getInputClass(!!errors.flowType)} 
                            disabled
                        >
                            <option value={OAUTH2_FLOW_TYPE.AUTHORIZATION_CODE}>Authorization code</option>
                            <option value={OAUTH2_FLOW_TYPE.DEVICE}>Device</option>
                        </select>
                        {errors.flowType && <div className={componentsStyles.errorText}>{errors.flowType.message}</div>}
                    </div>
                    {
                        flowTypeWatcher === OAUTH2_FLOW_TYPE.AUTHORIZATION_CODE && (
                            <div style={{
                                width: '100%',
                                boxSizing: 'border-box',
                                borderRadius: '5px', 
                                padding: '10px', 
                                backgroundColor: '#d5f5db',
                            }}>
                                <label className={componentsStyles.labelText} htmlFor='name'>Redirect URIs</label>
                                <div style={{
                                    maxHeight: '150px', 
                                    overflowY: 'scroll',
                                    marginTop: '10px', 
                                    padding: '5px',
                                }}>
                                    {fields.map((field, index) => (
                                        <div key={`redirectUri-${index}`}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                gap: 10,
                                            }}>
                                                <div>{index + 1}</div>
                                                <input 
                                                    key={field.id}
                                                    {...register(`redirectUris.${index}.uri`)} 
                                                    type='text' 
                                                    className={getInputClass(!!errors.redirectUris?.[index]?.uri)} 
                                                    style={{ backgroundColor: '#d5f5db' }}
                                                />
                                                <button 
                                                    type='button'
                                                    className={componentsStyles.button} 
                                                    style={{ width: 'unset' }}
                                                    onClick={() => removeUri(index)}
                                                >Delete</button>
                                            </div>
                                            {errors.redirectUris?.[index]?.uri && <div className={componentsStyles.errorText}>{errors.redirectUris?.[index]?.uri?.message}</div>}
                                        </div>
                                    ))}
                                    <div style={{ display: 'flex', justifyContent: 'left' }}>
                                        <button 
                                            type='button'
                                            className={componentsStyles.button} 
                                            style={{ 
                                                width: 'unset', 
                                                paddingLeft: 20, 
                                                paddingRight: 20,
                                                marginTop: 10,
                                            }}
                                            onClick={addUri}
                                        >Add</button>
                                    </div>
                                </div>
                                {errors.redirectUris && <div className={componentsStyles.errorText}>{errors.redirectUris.message}</div>}
                            </div>
                        )
                    }
                    <div>
                        {
                            (flowTypeWatcher === OAUTH2_FLOW_TYPE.AUTHORIZATION_CODE 
                            || flowTypeWatcher === OAUTH2_FLOW_TYPE.RESSOURCE_OWNER_PASSWORD) && (
                                <div>
                                    <input 
                                        {...register('flowSecurity')} 
                                        type='checkbox'
                                        name='flowSecurity'
                                        value='useClientSecret'
                                    />
                                    <label htmlFor='useClientSecret'>Use client secret</label>
                                </div>
                            )
                        }
                        { 
                            flowTypeWatcher === OAUTH2_FLOW_TYPE.AUTHORIZATION_CODE && (
                                <div>
                                    <input 
                                        {...register('flowSecurity')} 
                                        type='checkbox'
                                        name='flowSecurity'
                                        value='usePkce'
                                    />
                                    <label htmlFor='usePkce'>Use PKCE</label>
                                </div>
                            )
                        }
                        {errors.flowSecurity && <div className={componentsStyles.errorText}>{errors.flowSecurity.message}</div>}
                    </div>
                </div>
                <div className={componentsStyles.formBodyColumn}>
                    <div className={componentsStyles.inputGroup}>
                        <label className={componentsStyles.labelText} htmlFor='name'>Client ID</label>
                        <input
                            disabled
                            type='text' 
                            id='clientId' 
                            name='clientId'
                            value={client?.id ? client.id : ''}
                            className={getInputClass(false)} 
                        />
                    </div>
                    {client?.useClientSecret && <div className={componentsStyles.inputGroup}>
                        <label className={componentsStyles.labelText} htmlFor='name'>Client Secret</label>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '100%',
                            gap: 10,
                        }}>
                            <input
                                disabled
                                type={client?.clientSecret ? 'text' : 'password'}
                                id='clientSecret' 
                                name='clientSecret'
                                value={client?.clientSecret ? client.clientSecret : '*******************************************'}
                                className={getInputClass(false)} 
                            />
                            <button 
                                type='button'
                                className={componentsStyles.button} 
                                style={{ width: 'unset' }}
                                onClick={() => onResetSecret(client.id)}
                            >Regenerate</button>
                        </div>
                    </div>}
                    <div>
                        <input 
                            {...register('enabled')} 
                            type='checkbox'
                            name='enabled'
                            value='enabled'
                        />
                        <label htmlFor='enabled'>Enabled</label>
                    </div>
                </div>
            </div>
            <button type='submit' className={componentsStyles.button}>Confirm</button>            
        </form>
    );
}