import { UserLoginLocalDTO } from "@kalyzee/kast-app-module";

export interface LoginFormFields {
    email: string,
    password: string,
}

export const loginOutputFormater = ({
    email,
    password,
}: LoginFormFields): UserLoginLocalDTO => {
    return {
        email,
        password,
    };
}