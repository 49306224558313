import { get, set, remove } from "./localStorage";

enum TOKEN_TYPE {
    NORMAL = 'token',
    REFRESH = 'refreshToken',
}

const strOrUndef = (val: string | null) => val ? val : undefined;

const setToken = (type: TOKEN_TYPE, token: string) => set(type, token);
const getToken = (type: TOKEN_TYPE) => strOrUndef(get(type));
const removeToken = (type: TOKEN_TYPE) => remove(type);
const clearTokens = () => { remove(TOKEN_TYPE.NORMAL); remove(TOKEN_TYPE.REFRESH); }

export {
    TOKEN_TYPE,
    setToken,
    getToken,
    removeToken,
    clearTokens,
}