import { ClientCreateForm } from "../../features/oauth2/components/ClientCreateForm";

import containersStyle from '../../common/css/containers.module.css';

export const ClientCreatePage = () => {
    return (
        <div className={containersStyle.formContainer}>
            <ClientCreateForm />
        </div>
    );
}