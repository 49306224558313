const storage = localStorage;

const set = (key: string, value: string) => {
    storage.setItem(key, value);
}

const get = (key: string): string | null => {
    return storage.getItem(key);
}

const remove = (key: string) => {
    storage.removeItem(key);
}

const clear = () => {
    storage.clear();
}

export {
    set,
    get,
    remove,
    clear,
}