import { ErrorResponse } from "@kalyzee/kast-app-module";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { logout } from "../../features/auth/extraActions";
import { ThunkInput } from "./types";

const shouldLogout = (error: ErrorResponse) => {
    if (!error.status) return false;
    const breakingCodes = [403];
    return breakingCodes.indexOf(error.status) > -1;
}

export const extractErrorMessage = (error: any) => {
    let message = '';
    if (error.responseData?.message) {
        message = error.responseData.message;
    } else if (error.message) {
        message = error.message;
    } else {
        message = 'Unexpected error.';
    }
    return message;
}

export const createAppAsyncThunk = <T, U> (
    actionName: string,
    requestCreator: (data: T) => Promise<U>,
) => {
    return createAsyncThunk(
        actionName,
        async (input: ThunkInput<T, U>, { rejectWithValue, dispatch }) => {
            try {
                const response: U = await requestCreator(input.data);
                input.onSuccess?.(response);
                return response;
            } catch (error) {
                if (shouldLogout(error as ErrorResponse)) {
                    dispatch(logout());
                }
                return rejectWithValue(error);
            }
        }
    )
}