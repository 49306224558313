import componentsStyles from '../../../common/css/components.module.css';

import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { LoginFormFields, loginOutputFormater } from '../models';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { login, resetError, selectError } from '../authSlice';
import { ErrorNotifier } from '../../../common/components/ErrorNotifier';

const schema = yup.object({
    email: yup.string().email().required(),
    password: yup.string().required(),
}).required();

export const LoginForm = () => {
    const error = useAppSelector((state) => selectError(state));

    const { 
        register, 
        handleSubmit,
        formState: { errors, touchedFields, submitCount, dirtyFields },
    } = useForm<LoginFormFields>({
        resolver: yupResolver(schema),
    });

    const dispatch = useAppDispatch();

    const onSubmit: SubmitHandler<LoginFormFields> = (data: LoginFormFields) => {
        dispatch(login({ 
            data: loginOutputFormater(data),
        }));
    }

    const closeError = () => {
        dispatch(resetError());
    }

    return (
        <form className={componentsStyles.form}
            onSubmit={handleSubmit(onSubmit)}
        >
            <h1 className={componentsStyles.titleText}>Login to Kalyzée</h1>
            <div className={componentsStyles.formBody}>
                <ErrorNotifier error={error} onClose={closeError} />
                <div className={componentsStyles.inputGroup}>
                    <label className={componentsStyles.labelText} htmlFor='email'>Email</label>
                    <input {...register('email')} type='text' id='email' name='email' className={componentsStyles.input} />
                    {errors.email && <p className={componentsStyles.errorText}>Wrong email</p>}
                </div>
                <div className={componentsStyles.inputGroup}>
                    <label className={componentsStyles.labelText} htmlFor='password'>Password</label>
                    <input {...register('password')} type='password' id='password' name='password' className={componentsStyles.input} />
                    {errors.password && <p className={componentsStyles.errorText}>Wrong password</p>}
                </div>
            </div>
            <button type='submit' className={componentsStyles.button}>Confirm</button>
        </form>
    );
}