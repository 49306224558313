import { useState } from 'react';
import componentsStyles from '../../../common/css/components.module.css';
import styles from './AuthorizeForm.module.css';

export interface UserCodeFormProps {
    onCodeConfirmed: (value: string) => void,
}

export const UserCodeForm = ({ onCodeConfirmed }: UserCodeFormProps) => {
    const [code, setCode] = useState('');
    const [disabled, setDisabled] = useState(false);

    const onConfirm = (value: string) => {
        onCodeConfirmed(value);
        setDisabled(true);
    }

    const onCodeChange = (value: string) => {
        const cleanedValue = value
            .toUpperCase()
            .replace(/[^0-9a-z]/gi, '')
            .substring(0, 6);
        if (code === cleanedValue) return;
        setCode(cleanedValue);
        if (cleanedValue.length === 6) onConfirm(cleanedValue);
    }

    return (
        <div className={componentsStyles.form}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className={styles.header}>
                    <h1 className={styles.title}>Enter the code displayed on your device</h1>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <input 
                    value={code}
                    onChange={(e) => onCodeChange(e.target.value)}
                    className={componentsStyles.input}
                    style={{ fontSize: '2em' }} 
                    disabled={disabled}
                />
            </div>
        </div>
    );
}